import React, { ComponentProps } from 'react';
import { LuUser2 } from 'react-icons/lu';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Avatar, AvatarFallback } from '@/components/ui/avatar.tsx';
import { IDigitalTwinData } from '@/features/digital-twin/api/get-digital-twin.ts';
import { cn } from '@/lib/utils.ts';

const DigitalTwin = ({
    data,
    className,
}: {
    data: IDigitalTwinData;
    className?: ComponentProps<'div'>['className'];
}) => {
    const { id, name, createdAt } = data;

    return (
        <div
            className={cn(
                'grid h-10 w-full items-center gap-3 rounded-md border border-newDesign-divider bg-white px-2 py-1',
                'grid-cols-[auto_1fr_auto]',
                className,
            )}>
            <TooltipWrapper text={`Created at: ${createdAt}`}>
                <Avatar className='flex size-6 items-center rounded-full bg-newDesign-background-text'>
                    <AvatarFallback>
                        <LuUser2 />
                    </AvatarFallback>
                </Avatar>
            </TooltipWrapper>
            <label
                htmlFor={id}
                className='overflow-hidden text-ellipsis whitespace-nowrap'>
                {name}
            </label>
            <p className='mr-1 w-fit rounded-md bg-newDesign-background px-1 py-0.5 text-xs text-newDesign-secondary'>
                Digital Twin
            </p>
        </div>
    );
};

export default DigitalTwin;
