import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';
import React, { useState, useEffect } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa6';
import { IoIosClose } from 'react-icons/io';
import { LuLibrary, LuPencil, LuPlus } from 'react-icons/lu';
import { PiGear } from 'react-icons/pi';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CircularProgress } from '@material-ui/core';

import { LibraryUser, Organization } from '@bae/data-interface';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog.tsx';
import { DropdownMenuItem } from '@/components/ui/dropdown.tsx';
import { Label } from '@/components/ui/label.tsx';
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
} from '@/components/ui/select.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Switch } from '@/components/ui/switch.tsx';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table.tsx';
import { cn } from '@/lib/utils.ts';
import libraryApi from '@/libs/modules/library-management/src/lib/api.ts';

interface ILibraryManagementActions {
    libraryId: string;
    libraryName: string;
    organization: string;
    readOnly: boolean;
}

const LibraryManagementActions = ({
    libraryId,
    libraryName,
    organization,
    readOnly,
    owner,
}: {
    libraryId: string;
    libraryName: string;
    organization: string;
    readOnly: boolean;
    owner: boolean;
}) => {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openManageAccessDialog, setOpenManageAccessDialog] = useState(false);
    const history = useHistory();

    const handleEditManageAccess = () => {
        setOpenManageAccessDialog(true);
    };

    const handleDeleteSelected = () => {
        setOpenDeleteDialog(true);
    };

    const confirmDeleteSelected = () => {
        libraryApi.removeLibrary(libraryId).subscribe(
            () => {
                toast.success('Library deleted successfully');
            },
            () => {
                toast.error('Failed to delete library');
            },
        );
        setOpenDeleteDialog(false);
    };

    const handleRedirect = (id: string) => {
        history.push(`/library-designer/${id}`);
    };

    return (
        <>
            <DeleteDialog
                isModalOpen={openDeleteDialog}
                closeModal={() => setOpenDeleteDialog(false)}
                handleDelete={confirmDeleteSelected}
                libraryName={libraryName}
            />
            <ManageAccessDialog
                isModalOpen={openManageAccessDialog}
                closeModal={() => setOpenManageAccessDialog(false)}
                libraryName={libraryName}
                libraryId={libraryId}
                organization={organization}
                readOnly={readOnly}
            />
            <DropdownMenu>
                <DropdownMenuTrigger disabled={!owner} asChild>
                    <span>
                        <TooltipWrapper
                            text={
                                owner
                                    ? 'Actions'
                                    : 'Not owned by your organization'
                            }>
                            <button
                                disabled={!owner}
                                className='rounded-md p-1 hover:bg-newDesign-text-hover'>
                                <BsThreeDotsVertical
                                    className={cn(
                                        'size-5 text-newDesign-primary',
                                        {
                                            'cursor-not-allowed text-newDesign-text-secondary':
                                                !owner,
                                        },
                                    )}
                                />
                            </button>
                        </TooltipWrapper>
                    </span>
                </DropdownMenuTrigger>
                <DropdownMenuContent className='z-[999]'>
                    <div className='w-50 grid grid-cols-1 rounded-md border bg-white p-2 shadow-lg'>
                        {!readOnly && (
                            <DropdownMenuItem
                                className='w-full justify-start text-sm text-newDesign-text-primary'
                                onClick={() => handleRedirect(libraryId)}>
                                <LuPencil className='mr-2 size-5 text-newDesign-primary' />
                                Design
                            </DropdownMenuItem>
                        )}
                        <DropdownMenuItem
                            onClick={handleEditManageAccess}
                            className='w-full justify-start text-sm text-newDesign-text-primary'>
                            <PiGear className='mr-2 size-5 text-newDesign-primary' />
                            Manage Access
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={handleDeleteSelected}
                            className='w-full justify-start text-sm text-newDesign-text-primary'>
                            <RiDeleteBin2Line className='mr-2 size-5 text-newDesign-error' />
                            Delete
                        </DropdownMenuItem>
                    </div>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
};

const ManageAccessDialogContent = ({
    libraryId,
    organization,
    libraryUsers,
}: {
    libraryId: string;
    organization: string;
    libraryUsers: LibraryUser[];
}) => {
    const [addingNew, setAddingNew] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState<
        string | null
    >(null);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [organizations, setOrganizations] = useState<Organization[]>([]);

    const associatedItems = libraryUsers.map((d) => d.organizationId);
    const validOrganizations = organizations.filter(
        (d) => d.name !== organization && !associatedItems.includes(d.id),
    );

    useEffect(() => {
        libraryApi
            .getOrganizations()
            .subscribe((data) => setOrganizations(data));
    }, []);

    const handleAddNew = () => {
        setAddingNew(true);
    };

    const handleCancelAddNew = () => {
        setAddingNew(false);
        setSelectedOrganization(null);
        setIsReadOnly(false);
    };

    const handleAdd = () => {
        if (selectedOrganization) {
            const selectedOrg = organizations.find(
                (org) => org.id === selectedOrganization,
            );
            const data = {
                libraryId,
                organizationId: selectedOrganization,
                organizationName: selectedOrg?.name || '',
                readonly: isReadOnly,
            };
            libraryApi.addLibraryAccess(data).subscribe(
                () => {
                    toast.success('Added new organization');
                    handleCancelAddNew();
                },
                (error) => {
                    console.error(error);
                    toast.error('Failed to add new organization');
                },
            );
        }
    };

    const handleRemove = (data: LibraryUser) => () => {
        libraryApi.removeLibraryAccess(data).subscribe(
            () => toast.success('Removed organization successfully'),
            (error) => {
                console.error(error);
                toast.error('Failed to remove organization');
            },
        );
    };

    return (
        <DialogDescription>
            <div className='mb-2 flex items-center justify-between'>
                <p className='text'>Manage access</p>
                <Button
                    size='sm'
                    variant='text'
                    className='text-newDesign-secondary focus-visible:ring-0 focus-visible:ring-offset-0'
                    onClick={handleAddNew}>
                    <LuPlus className='mr-2' />
                    add new
                </Button>
            </div>
            <Table>
                <TableHeader className='bg-newDesign-background'>
                    <TableRow className=''>
                        <TableHead className='w-full'>
                            Organization Name
                        </TableHead>
                        <TableHead className='w-fit text-nowrap text-center'>
                            Read Only
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {addingNew && (
                        <TableRow>
                            <TableCell>
                                <div className='flex items-center gap-2 py-2.5'>
                                    <Select
                                        onValueChange={setSelectedOrganization}>
                                        <SelectTrigger className='w-full'>
                                            <SelectValue placeholder='No elements' />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {validOrganizations.map((org) => (
                                                <SelectItem
                                                    key={org.id}
                                                    value={org.id}>
                                                    {org.name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <Button
                                        variant='text'
                                        size='icon'
                                        className='aspect-square hover:bg-newDesign-text-hover'
                                        onClick={handleCancelAddNew}>
                                        <IoIosClose className='size-7 text-newDesign-error' />
                                    </Button>
                                    <Button
                                        variant='text'
                                        size='icon'
                                        className='aspect-square hover:bg-newDesign-text-hover'
                                        onClick={handleAdd}>
                                        <FaCheck className='size-5 text-newDesign-success' />
                                    </Button>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className=' mr-2 flex items-center gap-1.5'>
                                    <Label>No</Label>
                                    <Switch onCheckedChange={setIsReadOnly} />
                                    <Label>Yes</Label>
                                </div>
                            </TableCell>
                        </TableRow>
                    )}

                    {libraryUsers.map((libOrg) => (
                        <TableRow key={libOrg.organizationId}>
                            <TableCell>
                                <div className='flex items-center gap-2'>
                                    <Button
                                        onClick={handleRemove(libOrg)}
                                        size='sm'
                                        variant='text'
                                        className='bg-transparent p-2'>
                                        <RiDeleteBin2Line className='size-4 text-newDesign-error' />
                                    </Button>
                                    {libOrg.organizationName}
                                </div>
                            </TableCell>
                            <TableCell>
                                {libOrg.readonly ? (
                                    <div className='flex justify-center'>
                                        <FaCheck className='size-4 text-newDesign-primary' />
                                    </div>
                                ) : null}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </DialogDescription>
    );
};

interface IManageAccessDialog extends ILibraryManagementActions {
    isModalOpen: boolean;
    closeModal: () => void;
}

const ManageAccessDialog = (props: IManageAccessDialog) => {
    const { isModalOpen, closeModal, libraryName, libraryId } = props;

    const [loading, setLoading] = React.useState<boolean>(true);
    const [libraryUsers, setLibraryUsers] = React.useState<LibraryUser[]>([]);

    React.useEffect(() => {
        if (!libraryId || libraryId.length < 32) return;
        setLibraryUsers([]);
        setLoading(true);

        const subscription = libraryApi
            .getLibraryUsers(libraryId)
            .subscribe((response) => {
                setLibraryUsers(response);
                setLoading(false);
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [libraryId]);

    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        <div className='inline-flex items-center justify-center gap-2'>
                            <LuLibrary className='size-5 text-newDesign-primary' />
                            Library info: {libraryName}
                        </div>
                    </DialogTitle>
                </DialogHeader>

                {loading ? (
                    <div className='flex size-full items-center justify-center'>
                        <CircularProgress size={48} />
                    </div>
                ) : (
                    <ManageAccessDialogContent
                        libraryUsers={libraryUsers}
                        {...props}
                    />
                )}

                <Separator />
                <DialogFooter className='items-center'>
                    <Button onClick={closeModal}>Close</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

interface IDeleteDialog
    extends Pick<
        IManageAccessDialog,
        'isModalOpen' | 'closeModal' | 'libraryName'
    > {
    handleDelete: () => void;
}

const DeleteDialog = ({
    libraryName,
    isModalOpen,
    closeModal,
    handleDelete,
}: IDeleteDialog) => {
    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent className='max-w-sm'>
                <DialogHeader>
                    <DialogTitle className='flex items-center'>
                        <RiDeleteBin2Line className='mr-2 size-5 text-newDesign-error-dark' />
                        Remove library
                    </DialogTitle>
                </DialogHeader>
                <p>Are you sure you want to delete this item?</p>
                <ul className='list-disc pl-5'>
                    <li>{libraryName}</li>
                </ul>
                <Separator orientation='horizontal' />
                <DialogFooter className='items-center'>
                    <Button
                        onClick={closeModal}
                        variant='text'
                        className='text-newDesign-text-secondary'>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete}>Delete</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default LibraryManagementActions;
