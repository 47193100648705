import React from 'react';

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip.tsx';

const TooltipWrapper = ({
    children,
    pointerEvents = false,
    align = 'center',
    side = 'top',
    bgColor = 'bg-newDesign-tooltip',
    sideOffset = 8,
    text,
}: {
    pointerEvents?: boolean;
    align?: 'start' | 'center' | 'end';
    side?: 'left' | 'top' | 'right' | 'bottom';
    bgColor?: string;
    sideOffset?: number;
    children: React.ReactNode;
    text: string | null;
}) => {
    if (!text) return children;

    return (
        <TooltipProvider delayDuration={100}>
            <Tooltip>
                <TooltipTrigger asChild>{children}</TooltipTrigger>
                <TooltipContent
                    side={side}
                    align={align}
                    sideOffset={sideOffset}
                    className={`${pointerEvents ? '' : 'pointer-events-none select-none'} z-[9999] max-w-[350px] ${bgColor}`}>
                    <p className='break-words text-white'>{text}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export default TooltipWrapper;
