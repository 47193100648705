import { BaeRoute, RouteMap } from '@bae/routes';

import { AppTypes } from '@/app-config/appTypes.ts';

import {
    ComparisonSections,
    FinancialSections,
    InventorySections,
    KpiSections,
} from './types.ts';

interface AppConfig {
    enabledScenarioRoutes: BaeRoute[];
    kpiSections?: KpiSections[];
    financialSections?:
        | (FinancialSections | { section: FinancialSections; title: string })[]
        | null;
    inventorySections?: InventorySections[];
    comparisonSections?: ComparisonSections[];
}

interface Config {
    [AppTypes.STANDARD]: AppConfig;
    [AppTypes.GLOBAL_FABRIC]: AppConfig;
    [AppTypes.MIST]: AppConfig;
}

export const config: Config = {
    [AppTypes.STANDARD]: {
        enabledScenarioRoutes: [
            RouteMap.kpis,
            RouteMap.services,
            RouteMap.expenses,
            RouteMap.financials,
            RouteMap.topology,
            RouteMap.inventory,
            RouteMap.connmap,
            RouteMap.microdata,
            RouteMap.modelDesigner,
        ],
    },
    [AppTypes.GLOBAL_FABRIC]: {
        enabledScenarioRoutes: [
            RouteMap.kpis,
            RouteMap.expenses,
            RouteMap.financials,
            RouteMap.topology,
            RouteMap.inventory,
        ],
        kpiSections: [KpiSections.financialIndicators, KpiSections.cashFlow],
        financialSections: [
            FinancialSections.cashFlow,
            FinancialSections.operatingIncome,
            {
                section: FinancialSections.averagesEndPoint,
                title: 'Averages per Global Fabric site',
            },
            FinancialSections.averagesDataRate,
        ],
        inventorySections: [
            InventorySections.inventoryResources,
            InventorySections.inventoryFTE,
        ],
        comparisonSections: [
            ComparisonSections.comparisonDescription,
            ComparisonSections.financialIndicators,
            ComparisonSections.cashFlowComparison,
            ComparisonSections.indicatorsEvolution,
            ComparisonSections.fteComparisonTable,
        ],
    },
    [AppTypes.MIST]: {
        enabledScenarioRoutes: [
            RouteMap.kpis,
            RouteMap.expenses,
            RouteMap.financials,
            RouteMap.inventory,
        ],

        kpiSections: [KpiSections.financialIndicators, KpiSections.cashFlow],
        financialSections: [
            FinancialSections.cashFlow,
            FinancialSections.operatingIncome,
            {
                section: FinancialSections.averagesEndPoint,
                title: 'Averages per site',
            },
            FinancialSections.averagesTenant,
            FinancialSections.averagesDataRate,
        ],
        inventorySections: [
            InventorySections.inventoryResources,
            InventorySections.inventoryFTE,
        ],
        comparisonSections: [
            ComparisonSections.comparisonDescription,
            ComparisonSections.financialIndicators,
            ComparisonSections.cashFlowComparison,
            ComparisonSections.indicatorsEvolution,
            ComparisonSections.fteComparisonTable,
        ],
    },
};

export const getAppType = (): AppTypes => {
    const envAppType = (import.meta.env.VITE_APP_TYPE ||
        'standard') as AppTypes;

    if (envAppType) {
        localStorage.setItem('appType', envAppType);
        return envAppType;
    }

    throw new Error('App type not defined');
};

export const getDisabledFinancialStatements = () => {
    const appType = getAppType();

    if (appType === AppTypes.MIST || appType === AppTypes.GLOBAL_FABRIC) {
        return ['Revenue', 'EBITDA'];
    }

    return null;
};

export const getIndicatorsEvolutionDisabledOptions = () => {
    const appType = getAppType();

    const DISABLED_INDICATORS_BY_APP: { [key in AppTypes]?: string[] } = {
        [AppTypes.MIST]: [
            'margin',
            'revenue',
            'cashflow',
            'ebitda',
            'ebit',
            'kwh',
            'co2',
        ],
        [AppTypes.GLOBAL_FABRIC]: [
            'margin',
            'revenue',
            'cashflow',
            'ebitda',
            'ebit',
        ],
    };

    const DISABLED_KPIS_BY_APP: { [key in AppTypes]?: string[] } = {
        [AppTypes.MIST]: ['endpoints', 'datavolume', 'users', 'sessions'],
        [AppTypes.GLOBAL_FABRIC]: [
            'endpoints',
            'datavolume',
            'users',
            'sessions',
        ],
    };

    const disabledIndicators = DISABLED_INDICATORS_BY_APP[appType] || [];
    const disabledKpis = DISABLED_KPIS_BY_APP[appType] || [];

    return {
        disabledIndicators,
        disabledKpis,
    };
};

export function getEnabledAppFeatures(): AppConfig {
    const appType = getAppType();

    if (!appType || !config[appType]) {
        throw new Error('Invalid or nonexistent app type');
    }

    return config[appType];
}
