import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
    SortingState,
    RowSelectionState,
    getSortedRowModel,
    getFilteredRowModel,
    ColumnFiltersState,
} from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import { HiOutlineDocumentArrowUp } from 'react-icons/hi2';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { LuPlus, LuLibrary } from 'react-icons/lu';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { Library } from '@bae/data-interface';

import { Button } from '@/components/ui/button.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Label } from '@/components/ui/label.tsx';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table.tsx';

import LibraryManagementActions from './LibraryManagementActions.tsx';
import libraryApi from './api';

const SkeletonRows = () => {
    return Array.from({ length: 10 }, (_, index) => (
        <TableRow key={index}>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
            <TableCell>
                <Skeleton className='h-5 w-full bg-newDesign-divider' />
            </TableCell>
        </TableRow>
    ));
};

const DescriptionDialog = ({ description }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    return (
        <div className='ml-7'>
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogTrigger asChild>
                    <Button
                        variant='text'
                        size='sm'
                        withIcon
                        className='aspect-square px-0'
                        onClick={handleOpen}>
                        <IoDocumentTextOutline className='size-4 text-newDesign-primary' />
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Description</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>{description}</DialogDescription>
                    <DialogFooter>
                        <Button variant='outline' onClick={handleClose}>
                            Close
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const libraryTableColumns: ColumnDef<Library>[] = [
    {
        id: 'id',
        accessorKey: 'id',
        header: ({ table }) => (
            <Checkbox
                className='mr-2 mt-1 border-newDesign-text-secondary'
                onCheckedChange={(value) =>
                    table.toggleAllPageRowsSelected(!!value)
                }
                aria-label='Select all'
            />
        ),
        cell: ({ row }) => (
            <Checkbox
                className='mr-2 mt-1 border-newDesign-text-secondary '
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label='Select row'
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        id: 'name',
        accessorKey: 'name',
        header: ({ column }) => (
            <div className='flex items-center gap-1 text-nowrap'>
                <p className='font-semibold text-newDesign-text-primary'>
                    Library Name
                </p>
                <Button
                    variant='text'
                    size='sm'
                    withIcon
                    className='aspect-square bg-newDesign-background px-0 text-newDesign-text-primary'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }>
                    <ArrowUpDown className='size-4' />
                </Button>
            </div>
        ),
        cell: ({ row }) => {
            const name = row.original.name;
            return <div>{name}</div>;
        },
    },
    {
        id: 'organization',
        accessorKey: 'organization',
        header: ({ column }) => (
            <div className='flex items-center gap-1'>
                <p className='font-semibold text-newDesign-text-primary'>
                    Owner
                </p>
                <Button
                    variant='text'
                    size='sm'
                    withIcon
                    className='aspect-square bg-newDesign-background px-0 text-newDesign-text-primary'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }>
                    <ArrowUpDown className='size-4' />
                </Button>
            </div>
        ),
        cell: ({ row }) => {
            const owner: string = row.getValue('organization');
            return <div>{owner}</div>;
        },
    },
    {
        id: 'description',
        accessorKey: 'description',
        header: () => (
            <p className='font-semibold text-newDesign-text-primary'>
                Description
            </p>
        ),
        cell: ({ row }) => {
            const description = row.getValue('description');
            return description ? (
                <DescriptionDialog description={description} />
            ) : (
                <></>
            );
        },
    },
    {
        id: 'readonly',
        accessorKey: 'readonly',
        header: ({ column }) => (
            <div className='flex items-center gap-1'>
                <p className='whitespace-nowrap text-nowrap font-semibold text-newDesign-text-primary'>
                    Read Only
                </p>
                <Button
                    variant='text'
                    size='sm'
                    withIcon
                    className='aspect-square bg-newDesign-background px-0 text-newDesign-text-primary'
                    onClick={() =>
                        column.toggleSorting(column.getIsSorted() === 'asc')
                    }>
                    <ArrowUpDown className='size-4' />
                </Button>
            </div>
        ),
        cell: ({ row }) => {
            const value = row.getValue('readonly');
            return value ? (
                <div className='mr-10 flex justify-center'>
                    <FaCheck className='size-4 text-newDesign-primary' />
                </div>
            ) : null;
        },
    },
    {
        id: 'actions',
        header: () => <p className='text-newDesign-text-primary'>Actions</p>,
        cell: ({ row }) => {
            const readOnly = row.getValue('readonly');
            return readOnly ? null : (
                <div className='ml-3 flex'>
                    <LibraryManagementActions
                        owner={row.original.owner}
                        libraryId={row.getValue('id')}
                        libraryName={row.getValue('name')}
                        organization={row.getValue('organization')}
                        readOnly={row.getValue('readonly')}
                    />
                </div>
            );
        },
    },
];

const LibraryTable = ({
    searchTerm,
    data,
    loading,
    rowSelection,
    onRowSelectionChange,
}: {
    searchTerm: string;
    data: Library[];
    loading: boolean;
    rowSelection: RowSelectionState;
    onRowSelectionChange: React.Dispatch<
        React.SetStateAction<RowSelectionState>
    >;
}) => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    useEffect(() => {
        setColumnFilters([
            {
                id: 'name',
                value: searchTerm,
            },
        ]);
    }, [searchTerm]);

    const table = useReactTable<Library>({
        data,
        columns: libraryTableColumns,
        state: { sorting, columnFilters, rowSelection },
        onSortingChange: setSorting,
        onRowSelectionChange,
        getRowId: (row) => row.id,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: { pagination: { pageSize: 10 } },
    });

    return (
        <div>
            <Table>
                <TableHeader className='bg-newDesign-background'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id} className='text-base'>
                            {headerGroup.headers.map((header) => (
                                <TableHead
                                    key={header.id}
                                    className={
                                        header.column.id === 'name'
                                            ? 'w-full'
                                            : 'w-fit text-nowrap'
                                    }>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </TableHead>
                            ))}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {loading ? (
                        <SkeletonRows />
                    ) : table.getRowModel().rows.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell
                                        key={cell.id}
                                        className={
                                            cell.column.id === 'name'
                                                ? 'w-full'
                                                : 'w-fit text-nowrap'
                                        }>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={table.getAllColumns().length}>
                                No results.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <div className='flex items-center justify-end space-x-2 py-4'>
                <div className='text-muted-foreground flex-1 text-sm'>
                    {table.getFilteredSelectedRowModel().rows.length} of{' '}
                    {table.getFilteredRowModel().rows.length} library(ies)
                    selected.
                </div>
                <div className='flex space-x-2'>
                    <Select
                        value={table.getState().pagination.pageSize?.toString()}
                        onValueChange={(pageSize) => {
                            table.setPageSize(Number(pageSize));
                        }}>
                        <SelectTrigger className='h-8 w-[100px]'>
                            <SelectValue defaultValue='10' />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Page Size</SelectLabel>
                                {['10', '20', '30', '40', '50'].map(
                                    (pageSize) => (
                                        <SelectItem
                                            key={pageSize}
                                            value={pageSize}>
                                            Show {pageSize}
                                        </SelectItem>
                                    ),
                                )}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                    <Button
                        variant='outline'
                        size='sm'
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}>
                        Previous
                    </Button>
                    <Button
                        variant='outline'
                        size='sm'
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
};

const LibraryManagement = () => {
    const [libraries, setLibraries] = useState<Library[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedLibraries, setSelectedLibraries] = useState<Library[]>([]);

    const [loading, setLoading] = useState(true);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    useEffect(() => {
        const loadLibraries = libraryApi.getLibraries().subscribe(
            (data) => {
                setLibraries(data);
                setLoading(false);
            },
            () => {
                toast.error('Failed to load libraries');

                setLoading(false);
            },
        );

        return () => {
            loadLibraries.unsubscribe();
        };
    }, []);

    useEffect(() => {
        const selectedLibrariesIds = Object.keys(rowSelection);

        const librariesSelection = libraries.filter((lib) =>
            selectedLibrariesIds.includes(lib.id),
        );

        setSelectedLibraries(librariesSelection);
    }, [rowSelection, libraries]);

    const handleDeleteSelected = () => {
        setOpenDeleteDialog(true);
    };

    const confirmDeleteSelected = () => {
        selectedLibraries.forEach(({ id }) => {
            libraryApi.removeLibrary(id).subscribe(
                () => {
                    toast.success('Library deleted successfully');
                    setLibraries((prevLibraries) =>
                        prevLibraries.filter((lib) => lib.id !== id),
                    );
                },
                () => {
                    toast.error('Failed to delete library');
                },
            );
        });
        setOpenDeleteDialog(false);
        setRowSelection({});
    };

    return (
        <div className='w-full'>
            <p className='text-2xl font-bold '>Libraries</p>
            <CreateNewDialog
                isModalOpen={openAddDialog}
                closeModal={() => setOpenAddDialog(false)}
            />
            <DeleteDialog
                selectedLibraries={selectedLibraries}
                isModalOpen={openDeleteDialog}
                closeModal={() => setOpenDeleteDialog(false)}
                handleDelete={confirmDeleteSelected}
            />
            <p className='mt-4'>
                BAE Libraries empower you to piece together components as
                seamlessly as connecting Lego bricks, forming complex economic
                structures tailored to your strategic needs.
            </p>
            <ul className='mt-2 list-disc pl-4 text-newDesign-primary-active'>
                <li>
                    Build and Assemble:{' '}
                    <span className='text-newDesign-text-primary'>
                        {' '}
                        reusable model components e assemble them into your
                        network as needed.
                    </span>
                </li>
                <li>
                    Seamless Integration:{' '}
                    <span className='text-newDesign-text-primary'>
                        Effortlessly incorporate data, whether starting anew ou
                        leveraging Excel inputs.
                    </span>
                </li>
                <li>
                    Customize & Control:{' '}
                    <span className='text-newDesign-text-primary'>
                        Drive the evolution of your library toward targeted
                        results, with full command over customizations.
                    </span>
                </li>
            </ul>
            <div className='mt-4 inline-flex h-10 w-full gap-x-4'>
                <span className='relative w-full'>
                    <HiMiniMagnifyingGlass className='absolute left-4 top-1/2 size-5 translate-y-[-50%] text-newDesign-text-secondary' />
                    <Input
                        name='search'
                        placeholder='Search libraries'
                        value={searchTerm}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        className='h-10 pl-12'
                    />
                </span>
                <Button
                    variant='outline'
                    size='icon'
                    variantColor='alert'
                    disabled={selectedLibraries.length === 0}
                    onClick={handleDeleteSelected}
                    className='group border-newDesign-error bg-white hover:border-newDesign-error-light hover:bg-newDesign-error-light active:border-newDesign-error-dark active:bg-newDesign-error-dark'>
                    <RiDeleteBin2Line
                        className={`size-6 transition-colors ${selectedLibraries.length === 0 ? 'text-newDesign-text-secondary' : 'text-newDesign-error group-hover:text-white group-active:text-white'}`}
                    />
                </Button>
                <Button onClick={() => setOpenAddDialog(true)} withIcon>
                    <LuPlus className='mr-2 size-6' />
                    Create New
                </Button>
            </div>
            <div className='mt-4'>
                <LibraryTable
                    data={libraries}
                    loading={loading}
                    rowSelection={rowSelection}
                    onRowSelectionChange={setRowSelection}
                    searchTerm={searchTerm}
                />
            </div>
        </div>
    );
};

const DeleteDialog = ({
    selectedLibraries,
    isModalOpen,
    closeModal,
    handleDelete,
}: {
    selectedLibraries: Library[];
    isModalOpen: boolean;
    closeModal: () => void;
    handleDelete: () => void;
}) => {
    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent className='max-w-sm'>
                <DialogHeader>
                    <DialogTitle className='flex items-center'>
                        <RiDeleteBin2Line className='mr-2 size-5 text-newDesign-error-dark' />
                        Remove library
                    </DialogTitle>
                </DialogHeader>
                <p>Are you sure you want to delete this item?</p>
                <ul className='list-disc pl-5'>
                    {selectedLibraries.map(({ name }) => (
                        <li key={name}>{name}</li>
                    ))}
                </ul>
                <Separator orientation='horizontal' />
                <DialogFooter className='items-center'>
                    <Button
                        onClick={closeModal}
                        variant='text'
                        className='text-newDesign-text-secondary'>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete}>Delete</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const CreateNewDialog = ({
    isModalOpen,
    closeModal,
}: {
    isModalOpen: boolean;
    closeModal: () => void;
}) => {
    const [name, setName] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!isModalOpen) {
            setName('');
            setFile(null);
        }
    }, [isModalOpen]);

    const handleAdd = () => {
        if (name.trim()) {
            libraryApi.addLibrary(name, file).subscribe({
                next: () => {
                    toast.success('Library added successfully');
                    closeModal();
                },
                error: () => {
                    toast.error('Failed to add library');
                    closeModal();
                },
            });
        }
    };

    const handleFileButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files ? event.target.files[0] : null;
        setFile(selectedFile);
        if (selectedFile) {
            const fileNameWithoutExtension = selectedFile.name.split('.')[0];
            setName(fileNameWithoutExtension);
        }
    };

    const isCreateButtonDisabled = name.trim() === '';

    return (
        <Dialog open={isModalOpen} onOpenChange={closeModal}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        <div className='inline-flex items-center justify-center gap-2'>
                            <LuLibrary className='size-5 text-newDesign-primary' />
                            New Library
                        </div>
                    </DialogTitle>
                    <DialogDescription>
                        <p className='mb-2 text-newDesign-text-primary'>
                            Create a library from scratch
                        </p>
                    </DialogDescription>
                </DialogHeader>
                <div>
                    <Label htmlFor='libraryName'>Library name</Label>
                    <Input
                        id='libraryName'
                        name='libraryName'
                        placeholder='Enter the library name'
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        className=' h-10'
                    />
                    <Separator className='my-4 w-full' />
                    <p className='mb-2 text-newDesign-text-primary'>
                        or select a file to start a library from it. (optional)
                    </p>

                    <Button
                        variant='outline'
                        withIcon
                        size='sm'
                        className='w-full'
                        onClick={handleFileButtonClick}>
                        <HiOutlineDocumentArrowUp className='mr-2 size-5 text-newDesign-primary' />
                        Import file (.xlsx, .csv)
                    </Button>
                    <p className='text-sm italic text-newDesign-text-secondary'>
                        {file && `Uploaded file: ${file.name.split('.')[0]}`}
                    </p>
                    <Input
                        type='file'
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        className='hidden'
                    />
                </div>
                <Separator />
                <DialogFooter className='items-center'>
                    <Button
                        onClick={closeModal}
                        variant='text'
                        className='text-newDesign-text-secondary'>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAdd}
                        disabled={isCreateButtonDisabled}>
                        Create
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default LibraryManagement;
