import React from 'react';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa6';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import { IoIosClose } from 'react-icons/io';
import { LuPlus } from 'react-icons/lu';
import { PiGearLight } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RouteMap } from '@bae/routes';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogOverlay,
} from '@/components/ui/dialog.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Separator } from '@/components/ui/separator.tsx';

import { getId } from '../store/selectors';
import { getAllLabels } from './slice';

const { scenarios, labelsManagement } = RouteMap;

const LabelItem = ({ label, isSelected, onSelect, onRemove }) => {
    return (
        <TooltipWrapper text='Delete Label'>
            <div
                className='relative mt-4 flex cursor-pointer items-center justify-between px-2 py-1 [&:hover]:bg-gray-100'
                aria-label={label}
                tabIndex={0}
                role='button'
                id={`label-item-${label}`}
                onClick={
                    isSelected ? () => onRemove(label) : () => onSelect(label)
                }
                onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                        isSelected ? onRemove(label) : onSelect(label);
                    }
                }}>
                <p className='text-lg'>{label}</p>
                {isSelected && (
                    <IoIosClose className='mt-0.5 size-7 text-newDesign-error' />
                )}
            </div>
        </TooltipWrapper>
    );
};

const LabelsList = (props) => {
    const id = useSelector(getId);
    const { open, datacenter, onAddLabel, onRemoveLabel, onClose } = props;

    const labels = useSelector(getAllLabels);
    const history = useHistory();

    const [inputText, setInputText] = React.useState('');
    const [modeAdd, setModeAdd] = React.useState(false);

    const filteredLabels = labels.filter((l) =>
        l.toLocaleUpperCase().includes(inputText.toLocaleUpperCase()),
    );

    const handleAddNewLabel = () => {
        if (inputText.length === 0) return;

        onAddLabel(inputText);
        setInputText('');
        onClose();
        setModeAdd(false);
    };

    const handleClickCancel = () => {
        setModeAdd(false);
        setInputText('');
    };

    const handleRemove = (removedLabel) => {
        const label = datacenter.labels.find((l) => l === removedLabel);

        if (label) {
            onRemoveLabel(label);
        }
    };

    const handleClickConfig = () => {
        history.push(`${scenarios.path}/${id}/${labelsManagement.path}`);
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogOverlay className='bg-transparent' />
            <DialogContent className='max-w-md'>
                <DialogHeader>
                    <div className='flex items-center gap-1'>
                        {modeAdd ? 'Add new Label' : 'Select labels'}
                        {!modeAdd && (
                            <TooltipWrapper text='Config Data Center labels'>
                                <Button
                                    id='config-labels-button'
                                    size='icon'
                                    variant='text'
                                    onClick={handleClickConfig}
                                    color='secondary'>
                                    <PiGearLight className='size-6' />
                                </Button>
                            </TooltipWrapper>
                        )}
                        <TooltipWrapper
                            text={modeAdd ? 'Cancel and Return' : ''}>
                            {modeAdd ? (
                                <Button
                                    id='close-label-popover'
                                    size='icon'
                                    variant='text'
                                    onClick={handleClickCancel}>
                                    <BsArrowReturnLeft className='size-5' />
                                </Button>
                            ) : null}
                        </TooltipWrapper>
                    </div>
                </DialogHeader>
                <Separator />
                <div className=''>
                    {modeAdd ? (
                        <div className='relative flex gap-2 p-2'>
                            <Input
                                id='add-label-input'
                                required
                                color='secondary'
                                value={inputText}
                                className='w-full'
                                name='search'
                                placeholder='Insert label'
                                onChange={(event) =>
                                    setInputText(event.target.value)
                                }
                            />
                            <TooltipWrapper text='Save new label'>
                                <Button
                                    id='save-new-label-button'
                                    size='icon'
                                    variant='text'
                                    onClick={handleAddNewLabel}>
                                    <FaCheck className='size-6' />
                                </Button>
                            </TooltipWrapper>
                        </div>
                    ) : (
                        <div>
                            <div className='relative mb-4'>
                                <span className='relative w-full'>
                                    <HiMiniMagnifyingGlass className='absolute left-4 top-1/2 size-5 translate-y-[-50%] text-newDesign-text-secondary' />
                                    <Input
                                        id='search-labels-input'
                                        required
                                        color='secondary'
                                        value={inputText}
                                        name='search'
                                        className='pl-12'
                                        placeholder='Search labels'
                                        onChange={(event) =>
                                            setInputText(event.target.value)
                                        }
                                    />
                                </span>
                            </div>
                            <div className='flex max-h-[200px] flex-col overflow-auto'>
                                <Button
                                    id='add-new-label-button'
                                    aria-label='Add new label'
                                    variant='outline'
                                    color='secondary'
                                    onClick={() => setModeAdd(true)}>
                                    <LuPlus className='mr-2 size-5' />
                                    Add new Label
                                </Button>
                                {filteredLabels
                                    .filter(
                                        (label) =>
                                            label.toLocaleLowerCase() !== 'all',
                                    )
                                    .map((label) => {
                                        const isSelected =
                                            datacenter &&
                                            datacenter.labels.includes(label);

                                        return (
                                            <LabelItem
                                                key={label}
                                                label={label}
                                                isSelected={isSelected}
                                                onSelect={onAddLabel}
                                                onRemove={handleRemove}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LabelsList;
