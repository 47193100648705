import React, { ComponentProps } from 'react';
import { LuUser2 } from 'react-icons/lu';

import TooltipWrapper from '@/components/ui/TooltipWrapper.tsx';
import { Avatar, AvatarFallback } from '@/components/ui/avatar.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { IScenarioData } from '@/features/scenario/mock-data.ts';
import { cn } from '@/lib/utils.ts';

import ScenarioActions from './ScenarioActions.tsx';

const Scenario = ({
    data,
    className,
}: {
    data: IScenarioData;
    className?: ComponentProps<'div'>['className'];
}) => {
    const { id, name, lastUpdate, lastUpdateBy } = data;

    return (
        <div
            className={cn(
                'grid h-10 w-full items-center gap-3 rounded-md border border-newDesign-divider bg-white px-2 py-1',
                'grid-cols-[auto_1fr_auto]',
                className,
            )}>
            <TooltipWrapper text={`Last update by: ${lastUpdateBy}`}>
                <Avatar className='flex size-6 items-center rounded-full bg-newDesign-background-text'>
                    <AvatarFallback>
                        <LuUser2 />
                    </AvatarFallback>
                </Avatar>
            </TooltipWrapper>
            <label
                htmlFor={id}
                className='overflow-hidden text-ellipsis whitespace-nowrap'>
                {name}
            </label>
            <div className='flex h-full items-center gap-3'>
                <Separator orientation='vertical' />
                <p>{lastUpdate}</p>

                <ScenarioActions id={id} scenarioName={name} />
            </div>
        </div>
    );
};

export default Scenario;
